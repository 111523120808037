.why-work-p{
    margin: auto 0;
    padding: 50px;
}
.filters{
    margin: 20px 0px;
    display: flex;
    justify-content: space-evenly;
}
.filters select{
    min-width: 220px;
    background-color: #F5F4F4 !important;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    background: var(--layout-200, #F5F4F4);
}


@media only screen and (max-width:767px) {
    .filters select {
        min-width: 100px;
    }
    .why-work-p{
        padding: 10px;
    }
}