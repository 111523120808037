#root{
    background-color: #FAFAFA;
  
}
.sec-start{
  margin-top: 100px;
}
h1{
  font-weight: bold;
  margin-top: 40px;
}
/*form styles*/
form {
    /* General form styling */
    padding: 30px;
    box-shadow: 0px 0px 2px 2px #eeebeb;  
    border-radius: 5px;
    width: 100%; /* Adjust as needed */
    margin: 0 auto; /* Center the form */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],input[type="date"],
  input[type="tel"],
  textarea {
    background-color: #F5F4F4;
    padding: 10px;
    color: #96888B;
    border: none;
    margin-bottom: 10px;
  }
  
  textarea {
    height: 100px; /* Adjust as needed */
  }
  
  button[type="submit"] {
    color: #fff;
    font-weight: 600px;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    border-radius: 8px;
    background-color: #253d87;
  }
  #email, #mobileNumber{
    width: 100%;
  }
  #university, #degree, #query{
    margin-bottom: 40px;
  }
  #query{
    min-height: 120px;
  }
  /* Hover effect for the submit button */
  button[type="submit"]:hover {
    background-color: #7e98e7;
  }
  
  /* Input focus styles */
  input[type="text"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    border-color: #253D87;
    outline: none;
  } 
  
.sm-icons{
    cursor: pointer;
    background-color: #ECEAEA;
    width: 48px;
    border-radius: 100px;
    padding: 12px;
    margin-right: 10px;
}  
.sm-icons:hover{
    background-color: #BCB3B5;
}
.sm-icons-footer a{
    margin-right: 10px;
}
.contact-row{
    gap: 100px;
}
.contact-cards{
    padding: 15px;
    width: 100%;
}
.contact-cards-sec{
    display: flex;
    align-items: center;
}
.contact-cards p{
    margin: 0;
}
.contact-cards a{
    color: #000;
}
.contact-cards:hover{
    border-radius: 8px;
background: var(--layout-100, #FAFAFA);
box-shadow: 0px 0px 30px 4px rgba(128, 128, 128, 0.12);

}

/*Individual blog page carousel*/
.blogCarouselcard{
  height: 100%;
  margin: 10px;
  min-height: 450px;
}
.blogcarousel-img{
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.title{
  color: var(--layout-1000, #231F20);
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

/*Carousel Css*/
.slick-list {
  padding:0 10% 0 0 !important;
}
 /* carousel*/

 .custom-arrow { 
  position: absolute !important;
  background-color: #BCB3B5 !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 20px !important;
  top: -50px ;
  font-size: 14px;
 }
 .custom-arrow.clicked{
  background-color: #253D87 !important;
 }
 .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #fff !important;
 }
 
 .custom-next-arrow{
  right: 0px !important;
 }
 .custom-prev-arrow{
  right: 60px !important;
 }
 .carouselcard{
  padding: 20px;
 }
 .carousel-img{
  max-width: 200px;
  max-height: 220px;
    object-fit: contain;
 }
 .card-container{
  position: relative;

 }

 .card{
  z-index: 99;
  border-radius: 8px !important;
  margin: 10px;
 }
 
 .card-bg{
  border-radius: 8px;
  left: 0px;
  bottom: -10px;
  position: absolute;
  background-color: #253d87;
 }

/* accordion css */
/* Custom styles for accordion */
.custom-accordion-header {
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px;
border-bottom: 1px solid #dee2e6; /* Border bottom for items */
cursor: pointer;
}

.custom-accordion-header:hover {
background-color: #f8f9fa; /* Add background color on hover if needed */
}

/* Remove default accordion border and box-shadow */
.accordion {
border: none;
box-shadow: none;
}

/* Remove default accordion item border */
.accordion-item {
color: #fff;
border: none;
border-radius: none !important;
border-bottom: 2px solid #fff !important;
background-color: #253D87;
}

/* Style for accordion body if needed */
.accordion-body {
background-color: #253D87;
margin-bottom: 5px;
padding: 15px;
}
.accordion-button{
color: #fff;
background-color: #253D87;
border: none !important;
}
.accordion-button:focus {
color: #fff;
z-index: 3;
border-color: none !important;
outline: 0;
box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
color: #fff;
background-color: #253D87!important;
box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
background-image: url('../images/minus.svg');
}
.accordion-button::after {
background-image: url('../images/plus.svg');
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0 !important;

}
.accordion-item:last-of-type {
 border-radius: 0 !important;
}
.sm-icons-blog{
  display: flex;
  margin-bottom: 20px;
}

.section-img{
  width: 100%;
  object-fit: cover;
}
.star-content{
  border-radius: 12px;
  background-color:  #F5F4F4;
  padding: 20px;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  width: 48%;
  margin: 10px;
}
.number{
  margin-bottom: auto;
}

.number{
  color: var(--Alzea-Red-700, #C41F1D);
  border-radius: 100px;
  border: 2px solid var(--Alzea-Red-700, #C41F1D);
  display: flex;
  width: 37px;
  height: 37px;
  padding: 7px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.benefits-card{
  margin: 0 auto;
  padding: 24.8px;
  max-width: 253px;
  border-radius: 8.267px;
  background: var(--layout-200, #F5F4F4);
}
.benefits-card:hover{
  box-shadow: 0px 0px 30px 4px rgba(108, 108, 108, 0.12);
}
.student-testimonials{
  max-width: 900px;
  margin: 0 auto;
}
.options{
  display: flex;
  gap: 20px;
  background-color: #F5F4F4;
  margin: 10px 0;
  padding: 20px;
  border-radius: 12px;

}

.options:hover{
box-shadow: 0px 0px 30px 4px rgba(112, 112, 112, 0.12);
}

.servicemaintab{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.servicetab{
  gap: 20px;
  width: 510px;
  display: flex;
  padding: 22px 24px;
  align-items: center;
  border-radius: 8px;
  background:var(--layout-50, #FFF);
  color:  var(--layout-1000, #231F20);
  font-weight: bold;
  cursor: pointer;
}
.servicetab.active{
  color:var(--layout-50, #FFF);
  border-radius: 8px;
  background: var(--alzea-blue-800, #253D87);
}
.history{
  background: var(--Alzea-Blue-900, #1A2B60);
  color: #ffff;
  padding: 80px 64px;
}
.team-members{
  padding: 12px 18px;
  border-radius: 8px;
  background: var(--layout-200, #F5F4F4);
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.team-members a{
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

}
.team-members a .lkdn{
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.team-div{
  overflow: auto;
  display: flex;
  gap: 30px;
  margin: 20px 0;
}
.Team-sec{
  background: var(--layout-200, #F5F4F4);
  padding: 80px 120px;
}
.details{
  display: flex;
}
.details .role{
  padding: 10px;
  color: #fff;
  background-color: #253D87;
  border-radius: 5px;
}
.details .name{
  padding: 10px;
  background-color: #f5f4f4;
  border-radius: 5px;
}
.values-container{
  padding: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;

}
.values-div{
  display: flex;
  width: 360px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: var(--layout-200, #F5F4F4);
}
.values-div:hover{
  box-shadow: 0px 0px 30px 4px rgba(93, 93, 93, 0.12);
}
::-webkit-scrollbar-thumb {
  background: #253D87;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f5f4f4f4;
}

/* Pattern Style*/
.pattern , .pattern3{
  width: 150px;
  position: absolute;
  left: 0;
}
.pattern2, .pattern4{
  width: 150px;
  position: absolute;
  right: 0;
}
.pattern{
  top:-40px;
}
.pattern3{
  bottom: -40px;
}
.MuiPaper-root{
  border-radius: 12px;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}
.slick-center .MuiPaper-root{
  box-shadow: 0px 0px 30px 4px rgba(63, 63, 63, 0.16) !important;

}

.Team .slick-center .MuiPaper-root{
  min-height: 480px;
  box-shadow: 0px 0px 30px 4px rgba(63, 63, 63, 0.16) !important;
  margin: 0 auto;
}
.MuiSlider-markLabel {
  top: -15px;
}

.MuiSlider-mark {
  width: 10px !important;
  height: 10px !important;
  border-radius: 5px !important;
  top: 10px;
}
.MuiSlider-markActive {
  opacity: 1 !important;
  background-color:  #253D87 !important;
}
.MuiSlider-thumb {
 margin-left: 0 !important;
}
.MuiSlider-markLabel{
  font-weight: bold;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #BCB3B5;
  top: -26px !important;
}
.MuiSlider-mark, .MuiSlider-rail {
  color: #BCB3B5;
}
.MuiSlider-markLabelActive{
  color: var(--alzea-blue-800, #253D87) !important;
  font-weight: bold !important;
}
.MuiSlider-rail{
  opacity: 1 !important;
  height: 4px !important;
  color: #BCB3B5 !important;
}
.MuiSlider-track{
   height: 4px !important;
}
.MuiSlider-marked {
  margin-bottom: 0;
}
.card,.card-bg{
  width: 300px;
  height: 400px;
}
.blue-card{
  top: 22%;
}
.blue-card-pattern{
  top: 50px;
}
.aboutHead{
  color: var(--layout-1000, #231F20);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 75px */
  font-weight: bold;
}


@media only screen and (min-width: 1500px) {
.card,.card-bg{
  width: 350px;
  height: 450px;
}
}


@media only screen and (max-device-width: 1400px) 
{
  .star-content{
    width: 45%;
  }
.contact-row{
    gap: 30px;
}

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
   {
    .slick-slider{
      margin-top: 40px;
    }
    .aboutHead{
      font-size: 36px;
    }
    .sec-start{
      margin-top: 100px;
    }
    .card,.card-bg{
      width: 260px !important;
      height: 400px;
    }
  .slick-list {
    padding:0 5% 0 0 !important;
  }
}
@media only screen and (max-width:767px) {
  .MuiSlider-markLabel {
    font-size: 16px !important;
  }
  .aboutHead{
    font-size: 30px;
  }
  .student-content{
    margin-top: 50px;
  }
  .blue-card{
    top: 5%;
  }
  .blue-card-pattern{
    top: 0;
  }
  .MuiSlider-root{
    overflow: auto;
    width: 100%;
    min-height: 100px;
  }
  .MuiSlider-markLabel{
    margin-left: 10%;
    top: 0px !important;
  }
  .MuiSlider-thumb, .MuiSlider-rail, .MuiSlider-track{
    top: 47% !important;
  }
  .MuiSlider-mark ,.MuiSlider-markActive{
   top: 44%;
  }
  
 
  .pattern{
    width: 80px;
    top: -30px;
    left: 0;
  }
  .pattern2{
    width: 80px;
    right: 0;
  }
  .blogCarouselcard {
    margin: 0;
  }
  .faq-study-programs {
    padding: 15px;
  }
  .servicemaintab{
    flex-direction: column;
  }
  .servicetab{
    width: 100%;
  }
  .benefits-card{
    max-width: 100%;
  }
  .slick-slider{
    margin-top: 70px;
  }
  
  .star-content{
    width: 95%;
  }
  .sec-start{
    margin-top: 80px;
  }
  .sm-icons-blog{
    display: inline-block;
  }
  .slick-list {
    padding: 0 5% 0 0 !important;
  }
.card,.card-bg{
  width: 300px;
  height: 400px;
}
.custom-next-arrow{
  width: 40px;
  left: 60px !important;
 }
 .custom-prev-arrow{
  width: 40px;
  left: 0px !important;
 }
 .single-prev-arrow{
  left: 15px !important;
}
.single-next-arrow{
  left: 70px !important;
}
.single-prev-arrow, .single-next-arrow{
display: flex;
justify-content: center;
}
.history {
  padding: 25px 14px;
}
.values-container {
  padding: 15px;
}
.Team-sec {
  padding: 15px 20px;
}
}