/* Section 1 css */
body{
  background-color: #FAFAFA;
}
  .explore {
    position: relative;
  }
  
  .featherIconsfichevronsDown1 {
    position: relative;
    width: 28px;
    height: 28px;
    overflow: hidden;
    object-fit: cover;
  }

 /*Testimonials section*/

  video{
    border-radius: 20px;
  }
  .testimonails{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px;
   background-color:#253D87;
  }


  .button {
    min-width: 100px;
    position: absolute;
    top: 128px;
    border-radius: 8px;
    padding: 4px 2px;
    left: 45%;
  }
  
  .frameParent {
    position: relative;
    width: 100%;
    height: 729px;
    text-align: left;
    font-size: 24px;
    color: #a99ea0;
    font-family: Arboria-Medium;
    margin: 0 auto; /* Center-align horizontally */

  }

.main-container{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.main-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px; /* Set the height of the blurred border */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: blur(10px); /* Adjust the blur amount as needed */
  z-index: 1;
}
.weAreYourHeading{
    font-weight: bold;
    text-align: center;
    color: #231f20;
    display: inline-block;
    max-width: 550px;
    max-height: 125px;
}
.brands{
  margin-top: 20px;
  width: 100%;
}
.tags{
  min-height: 200px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
.tag1, .tag2, .tag3, .tag4{
  border-radius: 12px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
    position: absolute;
}
.tag1, .tag2{
  width: 200px;
  top: 40px;
}
.tag3 , .tag4{
  width: 150px;
  bottom: 0;
}
.tag1{
  background-color: #F7D3D3;
  color: #6A1110;
  left: 30px;
}
.tag3{
  background-color: #DFE5F6;
  color: #1A2B60;
  left: 0;
}
.tag2{
  background-color: #DFE5F6;
  color: #1A2B60;
  right: 30px;
}
.tag4{
  color: #6A1110;
  background-color: #F7D3D3;
  right: 0;
}
/* Section 2 css */
.programs .row{
  margin-top: 20px;
  margin-bottom: 20px;
}
.studyProgramsGroup{
  background-color:#F3F5FC;
  padding: 30px;
  border-radius: 10px;
}

.programsTitle{
  font-size: 30px;
  font-weight: bold;
}
.learnMoreButton{
  display: flex;
  padding-top: 20px;
  color: #253D87;
  font-weight: bold;
  text-decoration: none;
}


.internshipsGroup{
  background-color: #FCEDED;
  padding: 30px;
  border-radius: 10px;
}


   .programs{
    margin-top: 80px;
    margin-bottom: 40px;
   }

   /* Section 3 about us */
.about-us{
  color: #fff;
  position: relative;
  padding: 40px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#253D87;
}
.aboutimg,.AboutImage{
  display: flex;
  justify-content: center ;
  max-width: 600px;
}

.about-us .learnMoreButton{
  color: #AFBDE9;
}


   /* Section 3 styles */
   .our-mission-container{
    background-color: #FAFAFA;
   }

  
.faq-home{
  max-width: 1024px;
  padding: 30px;
  border-radius: 10px;
  background-color: #253D87;
  color: #fff;
}
.view-btn{
  font-weight: 600;
  color: #253D87;
  background: #AFBDE9;
  border: none;
  width: 100px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.form-img{
  width: 600px;
}

h2{
  font-weight: bold;
}
.study-trips-div{
  border-radius: 12px;
  background: var(--Alzea-Blue-100, #F3F5FC);
  padding: 48px;
}
.fluentzea{
  padding: 48px;
  border-radius: 12px;
  background: var(--Alzea-Red-100, #FCEDED);
}
.fluentzea-heading{
  color: var(--layout-1000, #231F20);
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 40px; /* 111.111% */
}
.studyTripsImage{
  max-height: 260px;
}
.programsImage,.fluentzeaimg, .internshipsImage,.studyTripsImage{
  margin: 10px 0;
}
.carousel-img{
  aspect-ratio: 4/5;
  position: relative;
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
   {
    .tag1, .tag2{
      top: 20px;
    }
    .explore {
      position: relative;
    }
    
    .featherIconsfichevronsDown1 {
      position: relative;
      width: 28.73px;
      height: 28.73px;
      overflow: hidden;
      flex-shrink: 0;
      object-fit: cover;
    }
    
    .button {
      position: absolute;
      top: 131.84px;
      left: calc(50% - 55.04px);
      border-radius: 8.21px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4.10px 2.05px;
    }
    
    .our-mission-container img{
      width: 680px;
    }

    .programs{
      margin-top: 20px;
      margin-bottom: 20px;
     }
    .studyProgramsGroup{
      margin-top: 20px;
      margin-bottom: 20px;
    }
   
    .about-us{
      gap: 0px;
    }
    .AboutImage{
      margin: 0 auto;
       max-width: 100%;
     }
   }

@media only screen and (max-width:767px) {
  .tag3,.tag4{
    bottom: 30px;
  }
  .studyProgramsGroup, .internshipsGroup, .study-trips-div, .fluentzea{
    padding: 20px;
  }
  .form-img{
    width: 100%;
  }
 
  .main-container::after {
    height: 150px;
  }
  .tags{
    margin: 15px 0px;
    min-height: 300px;
  }
  .studyProgramsGroup{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .programs{
    margin-top: 20px;
    margin-bottom: 20px;
   }
 
  .programsImage,.fluentzeaimg, .internshipsImage,.studyTripsImage{
    width: 300px;
  }

  .about-us{
    gap: 10px;
    padding: 20px 30px;
  }
  .AboutImage{
   margin: 0 auto;
    max-width: 100%
  }
  .tag1,.tag2{
    width: 130px;
  }
  .tag3, .tag4{
    width: 110px;
  }
  .weAreYourHeading {
    margin: 80px 0px;
  }
}

