.job-card {
    max-width: 960px;
    padding: 22px;
    margin: 20px auto;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--layout-200, #F5F4F4);
  }
  
  .job-title {
    color: var(--layout-1000, #231F20);
    /* header/header-2xl-bold */
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px; /* 133.333% */
  }
  
  .apply-now-btn {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 4px;
    border-radius: 8px;
    background: var(--alzea-blue-800, #253D87);
    color: white; /* Text color */
    border: none; /* Remove default button border */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  .apply-now-btn:hover {
    background: var(--alzea-blue-800, #6987e0); /* Change background color on hover */
   
  }
  