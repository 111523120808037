/* Remove default accordion border and box-shadow */.faqpage
.accordion {
    border: none;
    box-shadow: none;
    max-width: 1024px;
    margin: 40px auto;

  }
  .faqpage h1{
font-weight: bold;
padding: 40px 0;
}
  
  /* Remove default accordion item border */
 .faqpage .accordion-item {
    color: #000;
    border: none;
    border-radius: none !important;
    border-bottom: 1px solid #8f8585 !important;
    background-color: #fafafa ;
  }
  
  /* Style for accordion body if needed */
 .faqpage .accordion-body {
    background-color: #fafafa ;
    margin-bottom: 5px;
    padding-left: 30px;
  }
 .faqpage .accordion-button{
    font-weight: bold;
    color: #000;    
    background-color: #fafafa ;
    border: none !important;
    padding: 30px;
  }
 .faqpage .accordion-button:focus {
    color: #000;
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }
 .faqpage .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fafafa !important;
    box-shadow: none !important;
  }
  .faqpage .accordion-button:not(.collapsed)::after {
    background-image: url('../images/minus.svg');
  }
  .faqpage .accordion-button::after {
    background-image: url('../images/plus.svg');
  }