footer{
    padding: 50px 0px;
    background-color: #0D1630;
    color: #fff;
}
a{
    text-decoration: none;
    color: #fff;
}
.main-row ul{
    list-style-type: none;
    padding: 0;
}
.main-row{
    gap: 120px;
}
li{
    padding-top: 10px;
}
.copyright{
    background: var(--layout-200, #F5F4F4);
    color: #000;
    display: flex;
    height: 56px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 767px) {
    .main-row{
        gap: 30px;
    }
}
