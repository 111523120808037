.countrymaintab{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.countrytab{
    width: 510px;
    display: flex;
    padding: 22px 24px;
    align-items: center;
    border-radius: 8px;
    background:var(--layout-50, #FFF);
    color:  var(--layout-1000, #231F20);
    font-weight: bold;
    cursor: pointer;
}
.countrytab.active{
    color:var(--layout-50, #FFF);
    border-radius: 8px;
    background: var(--alzea-blue-800, #253D87);
}
.sprograms-accordion{
    font-size: 20px;
    background: var(--Alzea-Blue-900, #1A2B60);
    padding: 64px 120px;
    border-radius: 12px;
}

.sprograms-accordion .accordion-item {
    color: #fff;
    border: none;
    border-radius: none !important;
    border-bottom: 1px solid #fff !important;
    background: var(--Alzea-Blue-900, #1A2B60);
  }
  
  /* Style for accordion body if needed */
 .sprograms-accordion .accordion-body {
    background: var(--Alzea-Blue-900, #1A2B60);
    margin-bottom: 5px;
    padding-left: 30px;
  }
 .sprograms-accordion .accordion-button{
    font-size: 20px;
    font-weight: bold;
    color: #fff;    
    background: var(--Alzea-Blue-900, #1A2B60);
    border: none !important;
    padding: 30px;
  }
 .sprograms-accordion .accordion-button:focus {
    color: #fff;
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }
 .sprograms-accordion .accordion-button:not(.collapsed) {
    color: #fff;
    background: var(--Alzea-Blue-900, #1A2B60) !important;
    box-shadow: none !important;
  }

  /*Study programs faq*/
  .faq-study-programs{
    max-width: 1200px;
    padding: 40px;
    border-radius: 10px;
    background-color: #f5f4f4;
    color: #000;
    margin: 0 auto;
  }
  .view-btn{
    font-weight: 600;
    color: #fff;
    background: #253D87;
    border: none;
    width: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  /* accordion styles*/
 

.faq-study-programs .accordion-item {
    color: #000;
    border: none;
    border-radius: none !important;
    border-bottom: 1px solid #000 !important;
    background: var(--Alzea-Blue-900, #f5f4f4);
  }
  
  /* Style for accordion body if needed */
 .faq-study-programs .accordion-body {
    color: #000;
    background: var(--Alzea-Blue-900, #f5f4f4);
    padding-left: 30px;
  }
 .faq-study-programs .accordion-button{
    font-size: 20px;
    font-weight: bold;
    color: #000;    
    background: var(--Alzea-Blue-900, #f5f4f4);
    padding: 30px;

  }
 .faq-study-programs .accordion-button:focus {
    color: #000;
    z-index: 3;
    outline: 0;
    box-shadow: none !important;
  }
 .faq-study-programs .accordion-button:not(.collapsed) {
    color: #000;
    background: var(--Alzea-Blue-900, #f5f4f4) !important;
    box-shadow: none !important;
  }

  .programsmaintab{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .programstab{
    width: 160px;
    display: flex;
    justify-content: center;
    padding: 12px 14px;
    align-items: center;
    border-radius: 4px;
    background:var(--layout-50, #FFF);
    color:  var(--layout-1000, #231F20);
    cursor: pointer;
  }
  .programstab.active {
    color: var(--layout-50, #FFF);
    border-radius: 8px;
    background: var(--alzea-blue-800, #253D87);
}
.process-content{
  width: 200px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.process{
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
}

@media screen and (max-width: 1030px) and (min-width:768px) {
  .process{
    padding-left: 100px;
  }
}


@media only screen and (max-width: 767px) {
  .process-content{
    width: 150px;
    gap: 10px ;
    height: 100%;
    align-items: center;
  }
    .process{
      overflow: auto;
      scrollbar-width: none;
    }
    .sprograms-accordion{
        padding: 20px 0px;
        margin: 5px;
        font-size: 14px;
      }
      .sprograms-accordion .accordion-button {
        font-size: 14px;
      }
}
  