@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	
	box-sizing: border-box;
	font-family: sans-serif;
}

header {
	color: #000;
	background-color: #FAFAFA;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	z-index: 99;
	top: 0;
	width: 100%;
	height: 80px;
	padding: 0 2rem;
	color: var(--textColor);
	box-shadow: 0 0 2px 2px #eeebeb;
}
.menu{
	margin-left: auto;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}
.none{
	display: none;
}
header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: #FAFAFA;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}
.button-contact{
	color: #fff;
	padding: 8px 24px;
	border-radius: 8px;
    background: var(--alzea-blue-800, #253D87);
	text-decoration: none;
	
}
.button-contact:hover{
	color: #fff;
}
.dropdown-toggle::after{
	content: none !important;
}
.dropdown-menu{
	display: block !important;
}
.dropdown-toggle{
	border: none;
		background: none;
		color: #000;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}
    .button-contact {
	  width: 100%;
	  text-align: center;
	}
	header nav {
		z-index: 999;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		background-color: #fafafa;
		transition: 1s;
	}
	header a{
		width: 100%;
		padding-left: 5px;
	}
	.dropdown-toggle::after{
		content: none !important;
	}
	.custom-dropdown{
		width: 100%;
	}
	.dropdown-toggle{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
	}
    .logo-collapsed{
		padding-top: 25px;
		margin-right: auto;
	}
	header .responsive_nav {
		transform: translateY(100vh);
		padding: 0 30px;
	}

	nav .nav-close-btn {
		background-color: #fafafa;
		position: absolute;
		top: 0.7rem;
		right: 1.2rem;
	}

	nav a {
		font-size: 1rem;
		font-weight: bold;
	}
}