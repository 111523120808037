.offertrips1, .offertrips2{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}
.offertrips1-link{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    position: absolute;
    bottom: 70px;
    font-weight: bold;
    font-size: 25px;
}
.offertrips2-link{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    position: absolute;
    bottom: 70px;
    font-weight: bold;
    font-size: 25px;
}
.learn{
   width: 30px;
}
.faq-study-trips{
    max-width: 1200px;
    padding: 40px;
    border-radius: 10px;
    background-color: #f5f4f4;
    color: #000;
    margin: 0 auto;
  }
  .view-btn{
    font-weight: 600;
    color: #fff;
    background: #253D87;
    border: none;
    width: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  /* accordion styles*/
 

.faq-study-trips .accordion-item {
    color: #000;
    border: none;
    border-radius: none !important;
    border-bottom: 1px solid #000 !important;
    background: var(--Alzea-Blue-900, #f5f4f4);
  }
  
  /* Style for accordion body if needed */
 .faq-study-trips .accordion-body {
    background: var(--Alzea-Blue-900, #f5f4f4);
    padding-left: 30px;
  }
 .faq-study-trips .accordion-button{
    font-size: 20px;
    font-weight: bold;
    color: #000;    
    background: var(--Alzea-Blue-900, #f5f4f4);
    border: none !important;
    padding: 30px;
  }
 .faq-study-trips .accordion-button:focus {
    color: #000;
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }
 .faq-study-trips .accordion-button:not(.collapsed) {
    color: #000;
    background: var(--Alzea-Blue-900, #f5f4f4) !important;
    box-shadow: none !important;
  }

  /*form */
  #name, #college{
    width: 100%;
  }
/*cards accordion*/
.cardsAccordion{
    cursor: pointer;
    display: flex;
    gap: 10px;
    background-color: #f5f4f4;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.eiffel-content{
   padding: 120px;
   color: #fff;
}
.eiffel-button-contact{
    display: flex;
    height: 44px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    color: var(--Alzea-Blue-1000, #0D1630);
    width: 150px;
    font-weight: bold;
    background: var(--Alzea-Blue-300, #AFBDE9);
}

.whyAlzeaCarousel{
    background: var(--layout-200, #F5F4F4);
    max-width: 100%;
    margin: 10px;
}

.tab{
  background-color: #F5F4F4;
    display: flex !important;
    justify-content: center;
    padding: 80px;
}
.whyAlzeaCarousel .custom-next-arrow{
    bottom: 0 !important;
}

.why-go-through-us-p{
    display: flex;
    gap: 10px;
    padding: 5px;
  }
  
  .tick-img{
    height: 45px;
  }
  .whydoes-p{
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: center;
  }
  .whydoes-content{
    background-color: #f5f4f4;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 40px;
  }
  h3{
    font-weight: bold;
  }

@media only screen and (max-width:767px) {
  .faq-study-trips{
    padding: 10px;
  }
  .whydoes-content{
    padding: 20px;
  }
    .whydoes-p{
      padding: 5px;
    }
    .eiffel-content{
        padding: 30px;
    }
    .tab{
        padding: 10px;
    }
    .offertrips2-link, .offertrips1-link{
        bottom: 30px;
    }
    
}