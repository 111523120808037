@import url('https://fonts.googleapis.com/css2?family=Arboria-Book');
@import url('https://fonts.googleapis.com/css2?family=Scandia');

body {
  margin: 0;
  font-family: 'Arboria','Arboria-bold' sans-serif !important;
  color: #fafafa;

  
}
.Coming-soon{
  background-color: #253D87;
  height: 400px;
  max-width: 80%;
  border-radius: 20px;
  font-weight: 700;
  color: #fff;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
