.form-control{
    font-weight: bold;
    padding: 10px 30px !important;
    max-height: 45px;
    width: 200px !important;
    border: none !important;
    border-radius: 8px;
    background: var(--Alzea-Blue-200, #DFE5F6);
}
.form-control:focus{
    background: var(--Alzea-Blue-200, #DFE5F6);
    border-color: #fff;
    box-shadow: none;
}
.desc-filter{
    transform: rotate(180deg);
    
}
.filter{
    cursor: pointer;
}
.blogcard{
    border-radius: 12px;
    border: 1px solid var(--layout-300, #ECEAEA);
    background: var(--layout-100, #FAFAFA);
    padding: 25px;
    align-self: stretch;
}
.blogcard:hover{
    box-shadow: 0px 0px 30px 4px rgba(63, 63, 63, 0.12);
}
.blogcard img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blogcard .date{
    color: var(--layout-700, #827377);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.blogcard h3{
    color: var(--layout-1000, #231F20);
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px; /* 133.333% */
}
.type{
    border-radius: 4px;
    background: var(--Success-300, #D4F7DB);
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-weight: 500;
    color: #0F571F;
}
.blogs-filter{
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
}

.student-blogs, .featured-blogs{
border-radius: 12px;
border: 2px solid var(--layout-300, #ECEAEA);
padding: 20px;
}
.blog-contact-us{
    margin-top: 20px;
    gap: 26px;
    padding: 20px;
    border-radius: 12px;
    background: var(--layout-200, #F5F4F4);
}
.sm-icons-blog{
    gap: 16px;
}

.custom-select-container {
    position: relative;
  }
  
  .dropdown-icon {
    position: absolute;
    top: 22px;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s ease; /* Add transition for smooth rotation */
  }
  
  .rotate {
    transform: translateY(-50%) rotate(180deg); /* Rotate the image 180 degrees when isOpen is true */
  }
  
  